import classNames from 'classnames/bind';
import { IconLogoPaycashSwap, IconMoneyBag, IconPhone } from 'components/Icons';
import TabFocusableBtn from 'components/TabFocusableBtn';
import { KEY_DEFAULT_LNG, PAYCASH_SWAP_URL, ROUTE_MAP } from 'constant/indext';
import { getDownloadLinkParams, scrollIntoFooter } from 'helpers';
import React, { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const LANGUAGES = [
    { code: 'ru', label: 'Русский' },
    { code: 'en', label: 'English' }
];

const Header = ({ ready }: { ready: boolean }) => {
    const [t, i18n] = useTranslation();
    const navRef = useRef<HTMLDivElement>(null);
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const anotherLanguage = LANGUAGES.find(({ code }) => code !== i18n.language)!;

    const downloadLinkParams = getDownloadLinkParams();

    const isHomePage = useLocation().pathname === ROUTE_MAP.home;

    const toggleLanguage = () => {
        const lng = anotherLanguage.code;
        localStorage.setItem(KEY_DEFAULT_LNG, lng);
        i18n.changeLanguage(lng);
    };

    return (
        <Fragment>
            <header className={cx('Component', ready && 'show', isOpenSidebar && 'show-sidebar')}>
                <div className={cx('LeftSide')}>
                    <button
                        onClick={() => setIsOpenSidebar(!isOpenSidebar)}
                        type="button"
                        className={cx('BtnSidebar', isOpenSidebar && 'open')}
                    >
                        <span />
                        <span />
                        <span />
                    </button>

                    <Link className={cx('Logo')} to="/">
                        <img src={require('assets/images/logo.svg').default} alt="PayCash" />
                    </Link>
                    <TabFocusableBtn
                        onClick={toggleLanguage}
                        type="button"
                        className={cx('LanguageSwitcherController', 'Btn')}
                    >
                        {anotherLanguage.code}
                    </TabFocusableBtn>
                </div>
                <div className={cx('Center')}>
                    <div className={cx('NavWrapper')}>
                        <nav ref={navRef} className={cx('Nav')}>
                            <TabFocusableBtn className={cx(isHomePage && 'active')} tag="link" to={ROUTE_MAP.home}>
                                <IconMoneyBag />
                                <>{t('header.nav.home')}</>
                            </TabFocusableBtn>
                            <TabFocusableBtn target="_blank" tag="a" href={PAYCASH_SWAP_URL}>
                                <IconLogoPaycashSwap />
                                {t('header.nav.paycashswap')}
                            </TabFocusableBtn>
                        </nav>
                    </div>
                </div>
                <div className={cx('RightSide')}>
                    <TabFocusableBtn
                        {...downloadLinkParams}
                        onMouseMove={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            const pos = e.currentTarget.getBoundingClientRect();
                            const mx = e.clientX - pos.left - pos.width / 2;
                            const my = e.clientY - pos.top - pos.height / 2;

                            e.currentTarget.style.transform = 'translate(' + mx * 0.15 + 'px, ' + my * 0.3 + 'px)';
                            e.currentTarget.style.transform +=
                                'rotate3d(' + mx * -0.1 + ', ' + my * -0.3 + ', 0, 12deg)';
                            (e.currentTarget.children[0] as any).style.transform =
                                'translate(' + mx * 0.025 + 'px, ' + my * 0.075 + 'px)';
                        }}
                        onMouseLeave={(e: React.MouseEvent<HTMLAnchorElement>) => {
                            e.currentTarget.style.transform = 'translate3d(0px, 0px, 0px)';
                            e.currentTarget.style.transform += 'rotate3d(0, 0, 0, 0deg)';
                            (e.currentTarget.children[0] as any).style.transform = 'translate3d(0px, 0px, 0px)';
                        }}
                        className={cx('BtnDownload')}
                        tag={isHomePage || !downloadLinkParams.onClick ? 'a' : 'link'}
                        onClick={isHomePage ? downloadLinkParams.onClick : undefined}
                        to={isHomePage || !downloadLinkParams.onClick ? (undefined as any) : ROUTE_MAP.home}
                    >
                        <IconPhone />
                        <>{t('header.btnDownload')}</>
                    </TabFocusableBtn>
                </div>
            </header>

            <aside className={cx('Sidebar', isOpenSidebar && 'show')}>
                <nav>
                    <Link className={cx(isHomePage && 'active')} to={ROUTE_MAP.home}>
                        <IconMoneyBag />
                        <b>{t('header.nav.home')}</b>
                        <span>{t('header.nav.home_description')}</span>
                    </Link>
                    <a target="_blank" href={PAYCASH_SWAP_URL}>
                        <IconLogoPaycashSwap />
                        <b>{t('header.nav.paycashswap')}</b>
                        <span>{t('header.nav.paycashswap_description')}</span>
                    </a>
                </nav>

                <TabFocusableBtn
                    {...downloadLinkParams}
                    className={cx('BtnDownload')}
                    tag={isHomePage || !downloadLinkParams.onClick ? 'a' : 'link'}
                    onClick={
                        isHomePage
                            ? downloadLinkParams.onClick
                                ? (e: React.MouseEvent<HTMLAnchorElement>) => {
                                      scrollIntoFooter(e);
                                      setIsOpenSidebar(false);
                                  }
                                : undefined
                            : undefined
                    }
                    to={isHomePage || !downloadLinkParams.onClick ? (undefined as any) : ROUTE_MAP.home}
                >
                    <IconPhone />
                    <>{t('header.btnDownload')}</>
                </TabFocusableBtn>
            </aside>
        </Fragment>
    );
};

export default Header;
