import classNames from 'classnames/bind';
import React, { PropsWithChildren } from 'react';
import { Link, LinkProps as LinkComponentProps } from 'react-router-dom';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type ButtonTagProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'ref'> & {
    tag?: 'button';
};
type LinkTagProps = LinkComponentProps & {
    tag: 'link';
};
type AnchorTagProps = Omit<JSX.IntrinsicElements['a'], 'children' | 'ref'> & {
    tag: 'a';
};

export type Props = PropsWithChildren<ButtonTagProps | LinkTagProps | AnchorTagProps>;

const TabFocusableBtn = ({ children, className = '', ...props }: Props) => {
    const baseProps = {
        className: cx('Component', className),
        children: <span tabIndex={-1}>{children}</span>
    };

    if (props.tag === 'link') {
        return <Link {...props} {...baseProps} />;
    } else if (props.tag === 'a') {
        return <a {...props} {...baseProps} />;
    } else {
        return <button {...props} {...baseProps} />;
    }
};

export default TabFocusableBtn;
