import App from 'containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import 'scss/index.scss';
import 'i18n';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production')
    Sentry.init({
        dsn: 'https://2206bc8558c44b3c86a826c2e2cffd02@bugs.list.family/8',
        integrations: [
            new Integrations.BrowserTracing({
                // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
            })
        ],
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        tracesSampleRate: 1.0
    });

ReactDOM.render(
    <React.StrictMode>
        <App history={history} />
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.unregister();
