import Unlock from 'assets/lottie/unlock.json';
import classNames from 'classnames/bind';
import Button from 'components/Button';
import LottiePlayer, { AnimationItem } from 'lottie-web';
import React, { RefObject, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HomepageTimelines } from '../';
import { getDownloadLinkParams, usePixelRatio } from 'helpers';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Chat = ({
    chatRef,
    timelines,
    isScrollAnimationDisabled
}: {
    isScrollAnimationDisabled: boolean;
    chatRef: RefObject<HTMLElement>;
    timelines: HomepageTimelines;
}) => {
    const [t, { language }] = useTranslation();
    const unlockIconRef = useRef<HTMLDivElement>(null);
    const unlockIconAnimationRef = useRef<AnimationItem>(null);
    const pixelRatio = usePixelRatio();
    useEffect(() => {
        const timeline = timelines.chat;
        if (!timeline || !chatRef.current || isScrollAnimationDisabled) return;

        const runningText = chatRef.current.querySelector<HTMLParagraphElement>(`.${cx('ChatRunningText')}`)!;
        const chatApp = chatRef.current.querySelector<HTMLDivElement>(`.${cx('App')}`)!;
        const chatAppImages = chatApp.querySelectorAll<HTMLImageElement>('img')!;
        const leftSide = chatRef.current.querySelector<HTMLDivElement>(`.${cx('ChatLeftSide')}`)!;
        const rightSide = chatRef.current.querySelector<HTMLDivElement>(`.${cx('ChatRightSide')}`)!;

        const onScrollChatHandler = () => {
            const animateChatHandler = () => {
                if (!chatRef.current || !unlockIconRef.current || !unlockIconAnimationRef.current) return;

                // Start animation handler
                {
                    const progress = timeline.get('startAnimation').progress;
                    chatRef.current.style.backgroundColor = progress > 0.5 ? `rgb(251, 250, 246)` : '';

                    const moveX = Math.max(document.body.clientWidth, runningText.offsetWidth!);
                    runningText.style.transform = `translateX(${(moveX - moveX * 2 * progress).toFixed(
                        2
                    )}px)  translateY(-50%)`;

                    chatApp.style.transform = `scale(${1 + 1 - progress}) translateY(${(100 * (1 - progress)).toFixed(
                        2
                    )}%)`;
                    chatApp.style.opacity = Math.min(progress * 2, 1).toFixed(2);

                    runningText.style.willChange = progress > 0 && progress < 1 ? 'transform' : '';
                    chatApp.style.willChange = progress > 0 && progress < 1 ? 'transform, opacity' : '';
                }

                {
                    const content = timeline.get('content').progress;
                    const screen2 = timeline.get('screen2').progress;
                    const unlock = timeline.get('unlock').progress;
                    const screen3 = timeline.get('screen3').progress;

                    if (content) {
                        leftSide.classList.add(cx('show'));
                        rightSide.classList.add(cx('show'));
                    } else {
                        leftSide.classList.remove(cx('show'));
                        rightSide.classList.remove(cx('show'));
                    }

                    chatAppImages[1]!.style.opacity = screen2 ? '1' : '0';
                    chatAppImages[2]!.style.opacity = screen3 ? '1' : '0';
                    unlockIconAnimationRef.current.goToAndStop(
                        unlockIconAnimationRef.current.totalFrames * unlock,
                        true
                    );
                }
            };

            window.requestAnimationFrame(animateChatHandler);
        };

        const resetAnimation = () => {
            leftSide.classList.remove(cx('show'));
            rightSide.classList.remove(cx('show'));
        };

        window.addEventListener('scroll', onScrollChatHandler);
        return () => {
            window.removeEventListener('scroll', onScrollChatHandler);
            resetAnimation();
        };
    }, [timelines, isScrollAnimationDisabled]);

    return (
        <section ref={chatRef} className={cx('Chat')}>
            <div className={cx('ChatContent')}>
                <p className={cx('ChatRunningText')}>
                    <Trans i18nKey="pageHome.chat.title">
                        <span>.</span>
                    </Trans>
                </p>

                {isScrollAnimationDisabled && (
                    <div className={cx('Example')}>
                        <img
                            src={require(`assets/images/home_chat_phone_${language}@${pixelRatio}x.png`).default}
                            alt=""
                        />

                        <div className={cx('ExampleMessages')}>
                            {(t('pageHome.chat.messages', { returnObjects: true }) as string[]).map((msg) => {
                                return (
                                    <p key={msg}>
                                        {msg}

                                        <time>16:00</time>
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className={cx('ChatLeftSide')}>
                    <h2 className={cx('ChatTitle')}>
                        <Trans i18nKey="pageHome.chat.title">
                            <span>.</span>
                        </Trans>
                    </h2>

                    <p className={cx('Description')}>{t('pageHome.chat.description')}</p>

                    <div className={cx('ChatBtnGroup')}>
                        <Button tag="a" {...getDownloadLinkParams()} color="orange">
                            {t('pageHome.chat.btnDownload')}
                        </Button>
                        {/* <Button tag='link' to={ROUTE_MAP.chat} color='secondary'>
							{t('pageHome.chat.btnReadMore')}
						</Button> */}
                    </div>
                </div>
                <div className={cx('App')}>
                    <img
                        loading="lazy"
                        src={require(`assets/images/home_chat_1_${language}@${pixelRatio}x.png`).default}
                        alt=""
                    />
                    <img
                        loading="lazy"
                        src={require(`assets/images/home_chat_2_${language}@${pixelRatio}x.png`).default}
                        alt=""
                    />
                    <img
                        loading="lazy"
                        src={require(`assets/images/home_chat_3_${language}@${pixelRatio}x.png`).default}
                        alt=""
                    />

                    <UnlockIcon elementRef={unlockIconRef} animationRef={unlockIconAnimationRef} />
                </div>
                <div className={cx('ChatRightSide')}>
                    <p className={cx('Description')}>{t('pageHome.chat.description')}</p>
                </div>
            </div>
        </section>
    );
};

const UnlockIcon = ({
    elementRef,
    animationRef
}: {
    elementRef: RefObject<HTMLDivElement>;
    animationRef: RefObject<AnimationItem>;
}) => {
    useEffect(() => {
        if (!elementRef.current || elementRef.current?.children.length) return;
        (animationRef as any).current = LottiePlayer.loadAnimation({
            container: elementRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: Unlock,
            rendererSettings: {
                preserveAspectRatio: 'xMinYMin slice'
            }
        });
    }, []);

    return <div ref={elementRef} className={cx('AppLock')} />;
};

export default Chat;
