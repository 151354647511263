import { calcProgressByScroll } from 'helpers';

export type AnimationConfig = {
    /** uniquie string. using this value you can find animation in timeline  */
    label: string;
    /** where from start (read as offsetTop) of the target start animation (in pixels). If number is negative - animation starts before component become fully in viewport */
    start: number;
    /** duration of animation in pixels */
    duration: number;
    /**
     * pause between end of current animation and start of next animation
     * use this parameter, when you want to show result of animation for some time
     * affects on target's height
     */
    delayAfter?: number;
    previousAnimation?: AnimationConfig;
    nextAnimation?: AnimationConfig;
};

/**
 * @important Experimental technology
 * Creates Animation config, that help to control progress of animation
 * using its timeline target.
 */
export class Animation {
    label: string;
    start: number;
    duration: number;
    timeline: Timeline;
    previousAnimation?: AnimationConfig;
    delayAfter: number;
    nextAnimation?: AnimationConfig;

    constructor(config: AnimationConfig & { timeline: Timeline }) {
        this.label = config.label;
        this.start = config.start;
        this.duration = config.duration;
        this.previousAnimation = config.previousAnimation;
        this.nextAnimation = config.nextAnimation;
        this.delayAfter = config.delayAfter ?? 0;
        this.timeline = config.timeline;
    }

    /** duration of animation + pause after it */
    get fullDuration(): number {
        return this.duration + this.delayAfter;
    }

    /** point, offsetted by start + duration + delays. not based on target.offsetTop.
     * useful for setting start of the next animation
     */
    get end(): number {
        return this.start + this.duration + this.delayAfter;
    }

    // /** same as duration. not based on target.offsetTop. Also includes `delayAfter` */
    // get fullDuration(): number {
    // 	return this.start + this.duration + this.delayAfter
    // }

    get startPoint(): number {
        return this.start + this.timeline.target.offsetTop;
    }

    get endPoint(): number {
        return this.startPoint + this.duration;
    }

    get progress(): number {
        return calcProgressByScroll(window.scrollY, this.startPoint, this.endPoint);
    }
}

/**
 * @important Experimental technology
 * Helpful class, that can to controll progress of animation based on scrollY position
 * Creates list of animation, that easelly can be combined by using
 * classe's API. It also has important and helpful functions that helps to
 * calculate size of target element
 *
 * @todo add possible to set `pin` parameter instead of pinning myself
 */
export class Timeline {
    animations: Animation[] = [];
    target: HTMLDivElement;

    /**
     * @param target - container element with animations (read as "scene")
     * @param config - some params that help to move animation
     */
    constructor(
        target: HTMLDivElement,
        config?: {
            /**
             * You can provide value (in pixels) and run animation early or later than its default start value
             * In fact this param move target up or down, that allow to start first animation with
             * starting last animation in previous timeline, for example
             */
            timelineDelay?: number;
        }
    ) {
        this.target = target;
        if (config?.timelineDelay) {
            this.target.style.marginTop = config.timelineDelay + 'px';
        }
    }

    /** Add new aniation to timeline */
    add(
        constructConfig: (
            lastAnimationConfig?: Animation
        ) => Omit<AnimationConfig, 'start'> & Partial<Pick<AnimationConfig, 'start'>>
    ) {
        const lastAnimation = this.animations[this.animations.length - 1] ?? undefined;
        const newAnimationConfig = constructConfig(lastAnimation);
        const newAnimation = new Animation({
            ...newAnimationConfig,
            start: newAnimationConfig.start ?? lastAnimation?.end ?? 0,
            timeline: this
        });
        if (lastAnimation) lastAnimation.nextAnimation = newAnimation;
        newAnimation.previousAnimation = lastAnimation;
        this.animations.push(newAnimation);

        return newAnimation;
    }

    get(label: string) {
        return this.animations.find((animation) => animation.label === label)!;
    }

    /**
     * Set for target new height, that equal max of all durations in timeline or `minHeight` if in passed
     */
    recalcTargetHeight(minHeight: number = 0) {
        this.target.style.height =
            Math.max(
                this.animations.reduce((height, animation) => height + animation.fullDuration, 0),
                minHeight
            ) + 'px';
    }

    resetTargetHeight() {
        this.target.style.height = '';
        this.target.style.marginTop = '';
    }

    /**
     * Get progress of all timeline or of its part, finded by animation labels
     */
    progress(startAnimationLabel?: string, endAnimationLabel?: string) {
        const startPoint = startAnimationLabel
            ? this.get(startAnimationLabel)?.startPoint!
            : this.animations[0].startPoint;
        const endPoint = endAnimationLabel
            ? this.get(endAnimationLabel)?.endPoint!
            : this.animations[this.animations.length - 1].endPoint;
        return calcProgressByScroll(window.scrollY, startPoint, endPoint);
    }
}
