import classNames from 'classnames/bind';
import TabFocusableBtn, { Props as TabFocusableBtnProps } from 'components/TabFocusableBtn';
import React, { PropsWithChildren } from 'react';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = PropsWithChildren<{
    isActive?: boolean;
    color: 'blue' | 'light-blue' | 'orange' | 'secondary' | 'link' | 'dark';
}> &
    TabFocusableBtnProps;

const Button = ({ isActive, color, className = '', ...props }: Props) => {
    return <TabFocusableBtn {...props} className={cx('Component', color, isActive && 'active')} />;
};

export default Button;
