export default {
    header: {
        nav: {
            home: 'EOS кошелек',
            home_description: 'Безопасный и полностью\nанонимный EOS кошелек',
            paycashswap: 'PayCash Swap',
            paycashswap_description: 'Ваш лучший помощник в инвестировании'
        },

        btnDownload: 'Скачать приложение'
    },

    footer: {
        appCard: {
            title: 'Безопасный\nEOS кошелек',
            text: 'EOS кошелек с функциями крипточата,\nнаследства, обмена токенов и заработка'
        },
        tokenCard: {
            title: 'Cтейблкоин\nобеспечен\nна 200%',
            btn: 'Перейти'
        },

        nav: {
            policy: 'Политика конфиденциальности'
        },
        social: 'Подписывайтесь на наши социальные сети и следите\nза последними новостями и конкурсами',
        contactUs: 'Вы можете связаться с нами, если у вас есть предложения, жалобы или по любой другой причине'
    },

    global: {
        btnDownload: {
            btnAndroid: '<0>Доступно в</0>Google Play',
            btnIOS: '<0>Доступно в</0>App Store'
        },

        tokens: {
            USDCASH: 'Доллар',
            RUBCASH: 'Рубль',
            LIRUMOW: 'LiST Россия, Москва'
        }
    },

    pageHome: {
        intro: {
            title: 'Безопасный и\u00a0полностью\nанонимный <0>EOS\u00a0кошелек</0>',
            btnDownload: 'Скачать приложение'
        },

        advantages: {
            title: 'Преимущества',
            text: [
                'Каждый день 25 бесплатных транзакций',
                'Обмен токенов',
                'Покупка и продажа криптокеш токенов',
                'Крипто мессенджер для безопасного общения',
                'Заработок на DeFi проектах',
                'Полная поддержка криптокеш токенов'
            ]
        },

        transactions: {
            title: 'Транзакции.\nБесплатно.\n<0>Каждый\u00a0день.</0>',
            text: 'Для работы в EOS блокчейне нужно оплачивать ресурсы (транзакции) кошелька, мы же предоставляем каждый день 25  транзакций абсолютно бесплатно.',
            btnDownload: 'Скачать приложение',

            tabs: {
                resources: {
                    title: 'Ресурсы',
                    cpu: 'Процессор',
                    ram: 'Память',
                    net: 'Сеть'
                },
                transactions: {
                    title: 'Бесплатные транзакции',
                    of: 'из'
                }
            }
        },

        chat: {
            title: 'Лучший чат\n<0>на\u00a0блокчейне</0>',

            messages: ['Отправь 12 500 RUBCASH на кошелек 222537.pcash', 'Готово'],
            btnDownload: 'Скачать приложение',

            btnReadMore: 'Подробнее',

            description:
                'Ваши сообщения в полной безопасности, т.к. мы используем алгоритмы симметричного шифрования и сообщения доступны только на вашем устройстве. Общайтесь в полностью  анонимном чате. '
        },

        features: {
            exchange: {
                title: 'Обмен токенов',
                text: 'Вы можете моментально обменивать любые токены, выпущенные в блокчейне EOS в разделе «Обмен валют».'
            },
            investing: {
                title: 'Заработок на DeFi проектах',
                text: 'Зарабатывайте на конвертационных комиссиях, вкладывая свои средства в пул ликвидности.'
            },
            p2p: {
                title: 'Торговля криптокешами',
                text: 'Выводите на карту и оплачивайте покупку, используя криптокэш-токены. Вы также можете торговать токенами с другими пользователями.'
            },

            orders: 'Заявок',
            available: 'Доступно',
            limit: 'Лимит',
            btnSell: 'Продать',
            btnChat: 'Перейти в чат',

            btnDownload: 'Скачать приложение',
            btnReadMore: 'Подробнее',
            btnStatistics: 'Смотреть статистику'
        }
    },

    pagePolicy: {
        title: 'Политика конфиденциальности'
    }
};
