import classNames from 'classnames/bind';
import React, { RefObject, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'components/Slider';
import ReactSlickSlider from 'react-slick';
import { HomepageTimelines } from '..';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const SLIDER_AUTOPLAY_SPEED = 5000;

const Progress = ({ active }: { active?: boolean }) => {
    const size = 280;
    const r = (size - 5) / 2;
    const dasharray = Math.PI * (r * 2);

    return (
        <svg
            className={cx('Progress')}
            id="svg"
            version="1.1"
            height={size}
            width={size}
            viewBox={`0 0 ${size} ${size}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                r={r}
                cx={size / 2}
                cy={size / 2}
                fill="transparent"
                style={{
                    transition: active ? `${SLIDER_AUTOPLAY_SPEED}ms linear` : ''
                }}
                strokeDasharray={dasharray}
                strokeDashoffset={active ? 0 : dasharray}
            ></circle>
        </svg>
    );
};

const Advantages = ({
    advantagesRef,
    timelines,
    isScrollAnimationDisabled
}: {
    isScrollAnimationDisabled: boolean;
    timelines: HomepageTimelines;
    advantagesRef: RefObject<HTMLDivElement>;
}) => {
    const [t] = useTranslation();
    const [activeSlide, setActiveSlide] = useState<null | number>(null);
    const sliderRef = useRef<ReactSlickSlider>(null);

    useEffect(() => {
        if (isScrollAnimationDisabled) return;
        const timeline = timelines.advantages;

        if (!timeline) return;
        const content = advantagesRef.current!.querySelector<HTMLDivElement>(`.${cx('AdvantageContent')}`);
        const items = advantagesRef.current!.querySelectorAll<HTMLDivElement>(`.${cx('AdvantageItem')}`);

        const onScrollAdvantagesHandler = () => {
            const animateAdvantagesHandler = () => {
                if (!advantagesRef.current) return;

                if (timeline.get('animatedMobileOffset').progress === 1) {
                    content?.classList.add(cx('sticky'));
                } else {
                    content?.classList.remove(cx('sticky'));
                }

                /** first circle animation */

                const calculateCircleStyles = (
                    progress: number,
                    startTransform: number[][],
                    endTransform: number[][]
                ) => {
                    const willChange = progress > 0 && progress < 1 ? 'transform, opacity' : '';
                    const transform = `translate(${
                        startTransform[0][0] + (endTransform[0][0] - startTransform[0][0]) * progress
                    }%, ${startTransform[0][1] + (endTransform[0][1] - startTransform[0][1]) * progress}%) translate(${
                        startTransform[1][0] + (endTransform[1][0] - startTransform[1][0]) * progress
                    }px, ${startTransform[1][1] + (endTransform[1][1] - startTransform[1][1]) * progress}px)`;

                    return {
                        willChange,
                        transform
                    };
                };

                /**
				 * On first animation part all circles is in the center
				 * and it have to be scrolled to next positions
				 *  [
					"translate(-100%, -150%) translate(-210px, -10px)",
					"translate(0%, -150%) translate(210px, -10px)",
					"translate(-120%, -50%) translate(-210px, 0%)",
					"translate(20%, -50%) translate(210px, 0%)",
					"translate(-100%, 50%) translate(-210px, 10px)",
					"translate(0%, 50%) translate(210px, 10px)",
				  ];
				 */

                /** 1 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [-100, -150],
                            [-210, -10]
                        ],
                        [
                            [-150, -250],
                            [-210, -10]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle1').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[0].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[0].style.transform = styles.transform;
                    items[0].style.willChange = styles.willChange;
                }

                /** 2 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [0, -150],
                            [210, -10]
                        ],
                        [
                            [50, -250],
                            [210, -10]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle2').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[1].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[1].style.transform = styles.transform;
                    items[1].style.willChange = styles.willChange;
                }

                /** 3 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [-120, -50],
                            [-210, 0]
                        ],
                        [
                            [-190, -50],
                            [-210, 0]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle3').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[2].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[2].style.transform = styles.transform;
                    items[2].style.willChange = styles.willChange;
                }

                /** 4 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [20, -50],
                            [210, 0]
                        ],
                        [
                            [90, -50],
                            [210, 0]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle4').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[3].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[3].style.transform = styles.transform;
                    items[3].style.willChange = styles.willChange;
                }

                /** 5 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [-100, 50],
                            [-210, 10]
                        ],
                        [
                            [-150, 150],
                            [-210, 10]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle5').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[4].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[4].style.transform = styles.transform;
                    items[4].style.willChange = styles.willChange;
                }
                /** 6 circle */
                {
                    const transforms = [
                        [
                            [-50, -50],
                            [0, 0]
                        ],
                        [
                            [0, 50],
                            [210, 10]
                        ],
                        [
                            [50, 150],
                            [210, 10]
                        ]
                    ];
                    const firstAnimationPartProgress = timeline.get('showCircle6').progress;
                    const secondAnimationPartProgress = timeline.get('hideCircles').progress;

                    const styles =
                        secondAnimationPartProgress > 0
                            ? calculateCircleStyles(secondAnimationPartProgress, transforms[1], transforms[2])
                            : calculateCircleStyles(firstAnimationPartProgress, transforms[0], transforms[1]);
                    items[5].style.opacity =
                        secondAnimationPartProgress > 0
                            ? `${1 - secondAnimationPartProgress}`
                            : `${firstAnimationPartProgress}`;
                    items[5].style.transform = styles.transform;
                    items[5].style.willChange = styles.willChange;
                }
            };
            window.requestAnimationFrame(animateAdvantagesHandler);
        };

        const resetAnimation = () => {
            content?.classList.remove(cx('sticky'));
            items.forEach((el) => {
                el.style.transform = '';
                el.style.willChange = '';
                el.style.opacity = '';
            });
        };

        window.addEventListener('scroll', onScrollAdvantagesHandler);
        return () => {
            window.removeEventListener('scroll', onScrollAdvantagesHandler);
            resetAnimation();
        };
    }, [timelines, isScrollAnimationDisabled]);

    useEffect(() => {
        if (isScrollAnimationDisabled) setActiveSlide(0);
    }, [isScrollAnimationDisabled]);

    useEffect(() => {
        if (activeSlide === null) return;

        const tid = setTimeout(() => {
            let nextSlide = activeSlide + 1;
            setActiveSlide(nextSlide);
            sliderRef.current?.slickGoTo(nextSlide);
        }, SLIDER_AUTOPLAY_SPEED);

        return () => {
            clearTimeout(tid);
        };
    }, [activeSlide]);

    const Items = [
        <div className={cx('AdvantageItem')} key={0}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 0} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/speedometer.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.0')}</span>
            </p>
        </div>,

        <div className={cx('AdvantageItem')} key={1}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 1} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/messenger.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.3')}</span>
            </p>
        </div>,
        <div className={cx('AdvantageItem')} key={2}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 2} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/exchange.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.1')}</span>
                <span className={cx('AdvantageTag')}>SWAP</span>
            </p>
        </div>,
        <div className={cx('AdvantageItem')} key={3}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 3} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/credit-card.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.4')}</span>
            </p>
        </div>,

        <div className={cx('AdvantageItem')} key={4}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 4} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/credit-cards.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.2')}</span>
                <span className={cx('AdvantageTag')}>P2P</span>
            </p>
        </div>,
        <div className={cx('AdvantageItem')} key={5}>
            {isScrollAnimationDisabled && <Progress active={activeSlide === 5} />}
            <p className={cx('AdvantageItemContent')}>
                <img src={require('assets/icons/coins.svg').default} alt="" />
                <span>{t('pageHome.advantages.text.5')}</span>
            </p>
        </div>
    ];

    return (
        <div ref={advantagesRef} className={cx('Advantage')}>
            {isScrollAnimationDisabled ? (
                <Fragment>
                    <h2 className={cx('Title')}>{t('pageHome.advantages.title')}</h2>
                    <Slider ref={sliderRef} afterChange={setActiveSlide}>
                        {Items.map((item, i) => (
                            <div key={i} className={cx('ItemWrapper')}>
                                {item}
                            </div>
                        ))}
                    </Slider>
                </Fragment>
            ) : (
                <div className={cx('AdvantageContent')}>{Items}</div>
            )}
        </div>
    );
};

export default Advantages;
