import { KEY_DEFAULT_LNG } from 'constant/indext';
import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './ru';
import en from './en';

const options: InitOptions = {
    resources: {
        ru: {
            translation: ru
        },
        en: {
            translation: en
        }
    },
    get lng() {
        const lngList = Object.keys(this.resources!);
        let language = [window.localStorage.getItem(KEY_DEFAULT_LNG), navigator.language, navigator.userLanguage]
            .filter(Boolean)[0]
            ?.slice(0, 2)
            .toLowerCase();

        return lngList.includes(language!) ? language! : language === 'uk' ? 'ru' : 'en';
    },
    interpolation: {
        escapeValue: false
    }
};

export const LANGUAGES = Object.keys(options.resources!);

i18n.use(initReactI18next).init(options);

export default i18n;
