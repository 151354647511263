import classNames from 'classnames/bind';
import Button from 'components/Button';
import React, { RefObject, useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { Trans, useTranslation } from 'react-i18next';
import { HomepageTimelines } from '../';
import { getDownloadLinkParams, usePixelRatio } from 'helpers';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Transactions = ({
    transactionsRef,
    timelines,
    isScrollAnimationDisabled
}: {
    timelines: HomepageTimelines;
    isScrollAnimationDisabled: boolean;
    transactionsRef: RefObject<HTMLDivElement>;
}) => {
    const [t, { language }] = useTranslation();
    const pixelRatio = usePixelRatio();
    const [active, setActive] = useState<'resources' | 'transactions'>(
        isScrollAnimationDisabled ? 'transactions' : 'resources'
    );

    useEffect(() => {
        const timeline = timelines.transactions;
        if (!timeline || isScrollAnimationDisabled) return;

        const onScrollTransactionHandler = () => {
            const animateTransactionHandler = () => {
                if (!transactionsRef.current) return;

                const showResourcesInfoProgress = timeline.get('showResourcesInfo').progress;
                const showTransactionsInfoProgress = timeline.get('showTransactionsInfo').progress;
                const hidingTransactionsInfoProgress = timeline.get('hidingTransactionsInfo').progress;

                if (hidingTransactionsInfoProgress) {
                    transactionsRef.current!.classList.add(cx('hide'));
                    transactionsRef.current!.classList.remove(cx('show'));
                } else if (!showResourcesInfoProgress) {
                    transactionsRef.current!.classList.remove(cx('hide'));
                    transactionsRef.current!.classList.remove(cx('show'));
                } else {
                    transactionsRef.current!.classList.add(cx('show'));
                    transactionsRef.current!.classList.remove(cx('hide'));

                    setActive(showTransactionsInfoProgress ? 'transactions' : 'resources');
                }
            };
            window.requestAnimationFrame(animateTransactionHandler);
        };

        window.addEventListener('scroll', onScrollTransactionHandler);

        const resetAnimation = () => {
            transactionsRef.current!.classList.remove(cx('show'));
            transactionsRef.current!.classList.remove(cx('hide'));
        };

        return () => {
            window.removeEventListener('scroll', onScrollTransactionHandler);
            resetAnimation();
        };
    }, [timelines, isScrollAnimationDisabled]);

    useEffect(() => {
        setActive('transactions');
    }, [isScrollAnimationDisabled]);

    return (
        <section ref={transactionsRef} className={cx('Transactions')}>
            <div className={cx('TransactionsContent')}>
                <div className={cx('TransactionsCardBlock')}>
                    {isScrollAnimationDisabled && (
                        <img
                            className={cx('PhoneImg')}
                            alt=""
                            src={require(`assets/images/home_transactions_phone_${language}@${pixelRatio}x.png`)}
                        />
                    )}
                    <div className={cx('TransactionsCard')}>
                        <div className={cx('Tabs')}>
                            <span className={cx('TabsBg', active === 'transactions' && 'active')} />
                            <span className={cx(active === 'resources' && 'active')}>
                                {t('pageHome.transactions.tabs.resources.title')}
                            </span>
                            <span className={cx(active === 'transactions' && 'active')}>
                                {t('pageHome.transactions.tabs.transactions.title')}
                            </span>
                        </div>

                        <div className={cx('TransactionsCardContent')}>
                            <div className={cx('TransactionsCardTab', active === 'resources' && 'active')}>
                                <div className={cx('TransactionsCardItem')}>
                                    <div className={cx('TransactionsCardItemChart')}>
                                        <CircularProgressbar
                                            value={30}
                                            maxValue={100}
                                            text={`${30}%`}
                                            strokeWidth={10}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                pathColor: `#0066FF`,
                                                trailColor: '#EDEEEE',
                                                textColor: '#0066FF',
                                                textSize: '20px'
                                            })}
                                        />
                                    </div>
                                    <p className={cx('TransactionsCardItemTitle')}>
                                        {t('pageHome.transactions.tabs.resources.cpu')}
                                    </p>
                                    <p className={cx('TransactionsCardItemValue')}>0.135/0.450 мс</p>
                                </div>
                                <div className={cx('TransactionsCardItem')}>
                                    <div className={cx('TransactionsCardItemChart')}>
                                        <CircularProgressbar
                                            value={70}
                                            maxValue={100}
                                            text={`${70}%`}
                                            strokeWidth={10}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                pathColor: `#0066FF`,
                                                trailColor: '#EDEEEE',
                                                textColor: '#0066FF',
                                                textSize: '20px'
                                            })}
                                        />
                                    </div>
                                    <p className={cx('TransactionsCardItemTitle')}>
                                        {t('pageHome.transactions.tabs.resources.net')}
                                    </p>
                                    <p className={cx('TransactionsCardItemValue')}>142.982/204.26 Кб</p>
                                </div>
                                <div className={cx('TransactionsCardItem')}>
                                    <div className={cx('TransactionsCardItemChart')}>
                                        <CircularProgressbar
                                            value={50}
                                            maxValue={100}
                                            text={`${50}%`}
                                            strokeWidth={10}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                pathColor: `#0066FF`,
                                                trailColor: '#EDEEEE',
                                                textColor: '#0066FF',
                                                textSize: '20px'
                                            })}
                                        />
                                    </div>
                                    <p className={cx('TransactionsCardItemTitle')}>
                                        {t('pageHome.transactions.tabs.resources.ram')}
                                    </p>
                                    <p className={cx('TransactionsCardItemValue')}>5.5/11.00 Кб</p>
                                </div>
                            </div>
                            <div className={cx('TransactionsCardTab', active === 'transactions' && 'active')}>
                                <div className={cx('TransactionsCardItem')}>
                                    <div className={cx('TransactionsCardItemChart')}>
                                        <CircularProgressbar
                                            value={100}
                                            maxValue={100}
                                            text={`${25}`}
                                            strokeWidth={10}
                                            circleRatio={0.75}
                                            styles={buildStyles({
                                                rotation: 1 / 2 + 1 / 8,
                                                pathColor: `#0066FF`,
                                                trailColor: '#EDEEEE',
                                                textColor: '#0066FF',
                                                textSize: '20px'
                                            })}
                                        />
                                    </div>
                                    <p className={cx('TransactionsCardItemTitle')}>
                                        {t('pageHome.transactions.tabs.transactions.title')}
                                    </p>
                                    <p className={cx('TransactionsCardItemValue')}>
                                        25 {t('pageHome.transactions.tabs.transactions.of')} 25
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx('TransactionsDetails')}>
                    <h2>
                        <Trans i18nKey="pageHome.transactions.title">
                            <b>.</b>
                        </Trans>
                    </h2>

                    <p>{t('pageHome.transactions.text')}</p>

                    <Button color="blue" tag="a" {...getDownloadLinkParams()}>
                        {t('pageHome.transactions.btnDownload')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default Transactions;
