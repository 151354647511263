import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { usePixelRatio } from 'helpers';
import Button from 'components/Button';
import { ROUTE_MAP } from 'constant/indext';
import Header from 'components/Header';
const cx = classNames.bind(styles);

const PageNotFound = () => {
    const pixelRatio = usePixelRatio();
    return (
        <div className={cx('Component')}>
            <Header ready />

            <img src={require(`assets/images/page_404@${pixelRatio}x.png`)} alt="" />

            <div className={cx('Details')}>
                <code>Ошибка 404</code>
                <h1>
                    Страница
                    <br />
                    не найдена
                </h1>

                <p>
                    Вероятно, страница не существует
                    <br /> или вы ошиблись, когда вводили
                    <br /> адрес в строке браузера
                </p>

                <Button tag="link" to={ROUTE_MAP.home} color="blue">
                    На главную
                </Button>
            </div>
        </div>
    );
};

export default PageNotFound;
