import { ROUTE_MAP } from 'constant/indext';
import { History } from 'history';
import PageDev from 'pages/Dev';
import PageHome from 'pages/Home';
import PageNotFound from 'pages/NotFound';
import PagePolicy from 'pages/Policy';
import React, { useEffect } from 'react';
import { Route, Router, Switch, useLocation } from 'react-router-dom';

const ResetScrollHelper = () => {
    // Force scrollY 0
    useEffect(() => {
        if (window.history.scrollRestoration) {
            window.history.scrollRestoration = 'manual';
            window.scrollTo(0, 0);
        } else {
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
        }
    }, [useLocation().pathname]);

    return null;
};

const SEO = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.gtag?.('set', 'page', window.location.href);
        window.gtag?.('send', 'pageview');

        window.ym?.(75375226, 'hit', window.location.href);
    }, [pathname]);

    return null;
};

const App = ({ history }: { history: History }) => {
    return (
        <Router history={history}>
            <SEO />
            <Switch>
                <Route path={ROUTE_MAP.home} exact component={PageHome} />
                <Route path={[ROUTE_MAP.policy, ROUTE_MAP.privacyPolicy]} component={PagePolicy} />
                <Route
                    path={[
                        ROUTE_MAP.terms,
                        ROUTE_MAP.faq,
                        ROUTE_MAP.chat,
                        ROUTE_MAP.exchange,
                        ROUTE_MAP.p2p,
                        ROUTE_MAP.investing
                    ]}
                    component={PageDev}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
            <ResetScrollHelper />
        </Router>
    );
};

export default App;
