import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Header from 'components/Header';
import Button from 'components/Button';
import { ROUTE_MAP } from 'constant/indext';
import Loader from 'pages/Home/Intro/Loader';
const cx = classNames.bind(styles);

const PageDev = () => {
    return (
        <div className={cx('Component')}>
            <Header ready />
            <Loader isLoaded progress={100} />
            <div className={cx('Content')}>
                <h1>
                    Данная страница <span>в разработке</span>
                </h1>
                <Button tag="link" to={ROUTE_MAP.home} color="blue">
                    На главную
                </Button>
            </div>
        </div>
    );
};

export default PageDev;
