import { useState, useEffect } from 'react';

const useWidthCondition = (conditionFn: (width: number) => boolean) => {
    const check = () => conditionFn(window.innerWidth);

    const [condition, setCondition] = useState(check());

    useEffect(() => {
        const handleResize = () => {
            setCondition(check);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return condition;
};

export default useWidthCondition;
