import classNames from 'classnames/bind';
import { BREAKPOINT_IPAD } from 'constant/indext';
import useWidthCondition from 'helpers/useWidthCondition';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Advantages from './Advangates';
import { Timeline } from '../../helpers/AnimationTimelines';
import AppCanvas from './AppCanvas';
import Chat from './Chat';
import Features from './Features';
import Footer from 'components/Footer';
import Header from '../../components/Header';
import { constructRgbStyle, getClientHeight, usePixelRatio } from 'helpers';
import styles from './index.module.scss';
import Intro from './Intro';
import Transactions from './Transactions';
import { RGB } from 'helpers';
import { useTranslation } from 'react-i18next';
const cx = classNames.bind(styles);

export type HomepageTimelines = {
    intro: Timeline | null;
    advantages: Timeline | null;
    transactions: Timeline | null;
    chat: Timeline | null;
};

export const PAGE_BG_COLORS: RGB[] = [[], [237, 238, 247], [251, 250, 246]];
export const TOTAL_FRAMES = 88;
export const FIRST_FULL_SIZE_FRAME_INDEX = 82;
export const FINAL_INTRO_FRAME_INDEX = 35;
export const SCROLL_ANIMATION_BREAKPOINT = BREAKPOINT_IPAD;

/**
 * @important Improvement list
 * @todo - add possible to set `offset` to Animation (value mean how much pixels it have to be without change after animation)
 * @todo - check all adding/removing classes only if need (performance tip)
 */
const PageHome = () => {
    const imagesRef = useRef<{ [key in number]: HTMLImageElement }>({});
    const introRef = useRef<HTMLDivElement>(null);
    const isScrollAnimationDisabled = useWidthCondition((w) => {
        return w < SCROLL_ANIMATION_BREAKPOINT;
    });
    const pixelRatio = usePixelRatio();
    const containerRef = useRef<HTMLDivElement>(null);
    const advantagesRef = useRef<HTMLDivElement>(null);
    const transactionsRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const chatRef = useRef<HTMLElement>(null);
    const {
        i18n: { language }
    } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingPercent, setLoadingPercent] = useState(0);
    const [timelines, setTimelines] = useState<HomepageTimelines>({
        intro: null,
        advantages: null,
        transactions: null,
        chat: null
    });

    const generateTimelinesForPage = () => {
        if (loading || isScrollAnimationDisabled) {
            timelines.intro?.resetTargetHeight();
            timelines.advantages?.resetTargetHeight();
            timelines.transactions?.resetTargetHeight();
            timelines.chat?.resetTargetHeight();
            return;
        }

        const clientHeight = getClientHeight();
        const introTimeline = new Timeline(introRef.current!);
        const introAnimation = introTimeline.add(() => ({
            label: 'hidingContent',
            start: 150,
            duration: clientHeight - 150
        }));

        introTimeline.recalcTargetHeight(clientHeight);

        const advantagesTimeline = new Timeline(advantagesRef.current!, {
            timelineDelay: -introAnimation.duration - introAnimation.startPoint
        });

        advantagesTimeline.add(() => ({
            label: 'animatedMobileOffset',
            duration: 600
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle1',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle2',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle3',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle4',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle5',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'showCircle6',
            duration: 200
        }));

        advantagesTimeline.add(() => ({
            label: 'hideCircles',
            duration: 600
        }));

        const lastAdvantagesAnimation = advantagesTimeline.add(() => ({
            label: 'movingPhone',
            duration: clientHeight
        }));

        advantagesTimeline.recalcTargetHeight();

        const transactionsTimeline = new Timeline(transactionsRef.current!, {
            timelineDelay: -lastAdvantagesAnimation.duration
        });

        transactionsTimeline.add(() => ({
            label: 'showResourcesInfo',
            duration: clientHeight * 0.8
        }));
        transactionsTimeline.add(() => ({
            label: 'showTransactionsInfo',
            duration: clientHeight * 0.8
        }));
        const hidingTransactionsInfo = transactionsTimeline.add(() => ({
            label: 'hidingTransactionsInfo',
            duration: clientHeight
        }));
        transactionsTimeline.recalcTargetHeight();

        const chatTimeline = new Timeline(chatRef.current! as HTMLDivElement, {
            timelineDelay: -hidingTransactionsInfo.duration
        });

        chatTimeline.add(() => ({
            label: 'startAnimation',
            duration: clientHeight
        }));
        chatTimeline.add(() => ({
            label: 'content',
            duration: clientHeight * 0.8
        }));
        chatTimeline.add(() => ({
            label: 'screen2',
            duration: clientHeight
        }));
        chatTimeline.add(() => ({
            label: 'unlock',
            duration: clientHeight / 2
        }));
        chatTimeline.add(() => ({
            label: 'screen3',
            duration: clientHeight * 2
        }));
        chatTimeline.recalcTargetHeight();

        setTimelines({
            intro: introTimeline,
            advantages: advantagesTimeline,
            transactions: transactionsTimeline,
            chat: chatTimeline
        });
    };

    /** On resize after small delay update timeline with inner sizes */
    useEffect(() => {
        let tid: any = null;
        const onResize = () => {
            clearTimeout(tid);
            tid = setTimeout(generateTimelinesForPage, 200);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        if (isScrollAnimationDisabled) {
            setLoading(false);
            return;
        }
        setLoadingPercent(0);
        setLoading(true);
        imagesRef.current = {};
        const preloadImages = async () => {
            const FRAMES_TO_LOAD = TOTAL_FRAMES;
            Promise.all(
                new Array(TOTAL_FRAMES).fill(null).map((_, i) => {
                    const img = new Image();
                    img.src = require(`assets/images/phone_app_3d_${language}@${pixelRatio}x/${i
                        .toString()
                        .padStart(4, '0')}.png`).default;

                    return new Promise((resolve, reject) => {
                        img.onload = () => {
                            imagesRef.current[i + 1] = img;
                            setLoadingPercent((Object.keys(imagesRef.current).length / FRAMES_TO_LOAD) * 100);
                            resolve(true);
                        };
                    });
                })
            ).then(() => {
                setLoading(false);
            });
        };
        preloadImages();
    }, [isScrollAnimationDisabled, language]);

    useEffect(() => {
        if (loading || isScrollAnimationDisabled) return;

        const pageOnScrollHandler = () => {
            const pageAnimateHandler = () => {
                if (!chatRef.current || !containerRef.current) return;
                const scrollY = window.scrollY;
                const chatSectionOffsetTop = chatRef.current.offsetTop!;
                containerRef.current.style.willChange = 'background-color';
                if (scrollY <= 0) {
                    containerRef.current.style.backgroundColor = '';
                } else if (scrollY > chatSectionOffsetTop) {
                    containerRef.current.style.backgroundColor = constructRgbStyle(PAGE_BG_COLORS[2]);
                } else {
                    containerRef.current.style.backgroundColor = constructRgbStyle(PAGE_BG_COLORS[1]);
                }
            };
            window.requestAnimationFrame(pageAnimateHandler);
        };

        window.addEventListener('scroll', pageOnScrollHandler);

        return () => {
            window.removeEventListener('scroll', pageOnScrollHandler);
        };
    }, [loading, isScrollAnimationDisabled]);

    useEffect(generateTimelinesForPage, [loading, isScrollAnimationDisabled]);

    return (
        <div className={cx('Component')} ref={containerRef}>
            <Header ready={!loading} />
            {!isScrollAnimationDisabled && <AppCanvas loading={loading} imagesRef={imagesRef} timelines={timelines} />}
            <Intro
                isScrollAnimationDisabled={isScrollAnimationDisabled}
                loadingProgress={loadingPercent}
                loading={loading}
                timelines={timelines}
                introRef={introRef}
            />
            {!loading && (
                <Fragment>
                    <Advantages
                        isScrollAnimationDisabled={isScrollAnimationDisabled}
                        timelines={timelines}
                        advantagesRef={advantagesRef}
                    />
                    <Transactions
                        isScrollAnimationDisabled={isScrollAnimationDisabled}
                        timelines={timelines}
                        transactionsRef={transactionsRef}
                    />
                    <Chat
                        isScrollAnimationDisabled={isScrollAnimationDisabled}
                        timelines={timelines}
                        chatRef={chatRef}
                    />
                    <Features
                        isScrollAnimationDisabled={isScrollAnimationDisabled}
                        timelines={timelines}
                        featuresRef={featuresRef}
                    />
                    <Footer />
                </Fragment>
            )}
        </div>
    );
};

export default PageHome;
