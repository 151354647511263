import Button from 'components/Button';
import classNames from 'classnames/bind';
import {
    // IconCreditCardFlat,
    IconCreditCardsFlat,
    IconExchangeFlat
} from 'components/Icons';
import { BREAKPOINT_IPAD, PAYCASH_SWAP_URL } from 'constant/indext';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HomepageTimelines } from '../';
import { calcProgressByScroll, getClientHeight, getDownloadLinkParams, usePixelRatio } from 'helpers';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Features = ({
    featuresRef,
    isScrollAnimationDisabled
}: {
    timelines: HomepageTimelines;
    isScrollAnimationDisabled: boolean;
    featuresRef: RefObject<HTMLDivElement>;
}) => {
    const [t, { language }] = useTranslation();
    const tabsRef = useRef<HTMLUListElement>(null);
    const pixelRatio = usePixelRatio();
    const contentWrapperRef = useRef<HTMLDivElement>(null);

    const [active, setActive] = useState<null | 'exchange' | 'p2p' /* | 'investing' */>(null);

    const ANIMATION_DURATION = 10 * 1000;

    useEffect(() => {
        if (isScrollAnimationDisabled) return;

        const onScrollFeaturesHandler = () => {
            const animateFeaturesHandler = () => {
                if (!featuresRef.current) return;
                const scrollY = window.scrollY;
                const offsetTop = featuresRef.current.offsetTop!;
                const clientHeight = getClientHeight();
                const startPoint = offsetTop - clientHeight;
                if (scrollY > startPoint) {
                    const progress = calcProgressByScroll(
                        scrollY,
                        startPoint,
                        startPoint + featuresRef.current.offsetHeight
                    );

                    featuresRef.current.childNodes.forEach((node) => {
                        const element = node as HTMLDivElement;
                        element.style.willChange = progress > 0 ? 'transform' : 'auto';
                        element.style.transform = `translateY(-${(100 * (1 - progress)).toFixed(2)}%)`;
                    });
                }
            };

            window.requestAnimationFrame(animateFeaturesHandler);
        };
        window.addEventListener('scroll', onScrollFeaturesHandler);

        const resetAnimation = () => {
            featuresRef.current?.childNodes.forEach((node) => {
                const element = node as HTMLDivElement;
                element.style.willChange = '';
                element.style.transform = ``;
            });
        };

        return () => {
            window.removeEventListener('scroll', onScrollFeaturesHandler);
            resetAnimation();
        };
    }, [isScrollAnimationDisabled]);

    useEffect(() => {
        let tid: any = null;
        const onscroll = () => {
            if (!featuresRef.current) return;
            if (window.scrollY >= featuresRef.current.offsetTop! - document.body.clientHeight) {
                if (!active) setActive('exchange');
            } else {
                setActive(null);
                clearTimeout(tid);
            }
        };
        window.addEventListener('scroll', onscroll);

        if (active) {
            if (isScrollAnimationDisabled && tabsRef.current) {
                tabsRef.current.scrollTo({
                    behavior: 'smooth',
                    left: (tabsRef.current.querySelector<HTMLLIElement>(`li.${cx('active')}`)?.offsetLeft ?? 0) - 8
                });
            }

            const ACTIVE_MAP: {
                [key in Exclude<typeof active, null>]: typeof active;
            } = {
                // exchange: 'investing',
                // investing: 'p2p',
                // p2p: 'exchange',
                exchange: 'p2p',
                p2p: 'exchange'
            };

            tid = setTimeout(() => {
                setActive(ACTIVE_MAP[active]);
            }, ANIMATION_DURATION);
        }

        return () => {
            clearTimeout(tid);
            window.removeEventListener('scroll', onscroll);
        };
    }, [active]);

    useEffect(() => {
        const onResize = () => {
            if (!contentWrapperRef.current) return;

            if (window.innerWidth < BREAKPOINT_IPAD) {
                let max = 0;
                contentWrapperRef.current.childNodes.forEach((el) => {
                    const element = el as HTMLDivElement;
                    element.style.height = 'auto';
                    if (max < element.clientHeight) max = element.clientHeight;
                    element.style.height = '';
                });

                contentWrapperRef.current.style.height = max + 'px';
            } else {
                contentWrapperRef.current.style.height = '';
            }
        };

        onResize();
        window.addEventListener('resize', () => onResize());

        return () => {
            window.removeEventListener('resize', () => onResize());
        };
    }, []);

    const DownloadBtn = (
        <Button tag="a" {...getDownloadLinkParams()} color="blue">
            {t('pageHome.features.btnDownload')}
        </Button>
    );

    return (
        <section ref={featuresRef} className={cx('Features')}>
            <div className={cx('FeaturesWrapper')}>
                <div className={cx('FeaturesContainer')}>
                    <div className={cx('FeaturesAppScreen')}>
                        <img
                            loading="lazy"
                            style={{
                                opacity: Number(active === 'exchange')
                            }}
                            src={
                                require(`assets/images/home_features_exchange${
                                    isScrollAnimationDisabled ? '_phone' : ''
                                }_${language}@${pixelRatio}x.png`).default
                            }
                            alt=""
                        />
                        {/* <img
							loading='lazy'
							style={{
								opacity: Number(active === 'investing'),
							}}
							src={require(`assets/images/home_features_investing${
								isScrollAnimationDisabled ? '_phone' : ''
							}_${language}@${pixelRatio}x.png`).default}
							alt=''
						/> */}
                        <img
                            loading="lazy"
                            style={{
                                opacity: Number(active === 'p2p')
                            }}
                            src={
                                require(`assets/images/home_features_p2p${
                                    isScrollAnimationDisabled ? '_phone' : ''
                                }_${language}@${pixelRatio}x.png`).default
                            }
                            alt=""
                        />
                    </div>

                    <div className={cx('FeaturesDetails')}>
                        <ul ref={tabsRef} className={cx('Tabs')}>
                            <li
                                role="button"
                                onClick={() => setActive('exchange')}
                                className={cx(active === 'exchange' && 'active')}
                            >
                                <div className={cx('TabWrapper')}>
                                    <IconExchangeFlat />
                                    {t('pageHome.features.exchange.title')}
                                    <span className={cx('ProgressBar')}>
                                        <span
                                            style={{ animationDuration: ANIMATION_DURATION + 'ms' }}
                                            className={cx('Progress')}
                                        />
                                    </span>
                                </div>
                            </li>
                            {/* <li
								role='button'
								onClick={() => setActive('investing')}
								className={cx(active === 'investing' && 'active')}
							>
								<div className={cx('TabWrapper')}>
									<IconCreditCardFlat />
									{t('pageHome.features.investing.title')}
									<span className={cx('ProgressBar')}>
										<span
											style={{ animationDuration: ANIMATION_DURATION + 'ms' }}
											className={cx('Progress')}
										/>
									</span>
								</div>
							</li> */}
                            <li
                                role="button"
                                onClick={() => setActive('p2p')}
                                className={cx(active === 'p2p' && 'active')}
                            >
                                <div className={cx('TabWrapper')}>
                                    <IconCreditCardsFlat />
                                    {t('pageHome.features.p2p.title')}
                                    <span className={cx('ProgressBar')}>
                                        <span
                                            style={{ animationDuration: ANIMATION_DURATION + 'ms' }}
                                            className={cx('Progress')}
                                        />
                                    </span>
                                </div>
                            </li>
                        </ul>

                        <div ref={contentWrapperRef} className={cx('FeaturesTabsContentWrapper')}>
                            <div className={cx('FeaturesTabsContent', active === 'exchange' && 'active')}>
                                <h2>{t('pageHome.features.exchange.title')}</h2>
                                <p>{t('pageHome.features.exchange.text')}</p>
                                <div className={cx('FeaturesBtnGroup')}>{DownloadBtn}</div>
                            </div>
                            {/* <div
								className={cx(
									'FeaturesTabsContent',
									active === 'investing' && 'active'
								)}
							>
								<h2>{t('pageHome.features.investing.title')}</h2>
								<p>{t('pageHome.features.investing.text')}</p>
								<div className={cx('FeaturesBtnGroup')}>
									{DownloadBtn}
								</div>
							</div> */}
                            <div className={cx('FeaturesTabsContent', active === 'p2p' && 'active')}>
                                <h2>{t('pageHome.features.p2p.title')}</h2>
                                <p>{t('pageHome.features.p2p.text')}</p>
                                <div className={cx('FeaturesBtnGroup')}>
                                    {DownloadBtn}

                                    <Button tag="a" target="_blank" href={PAYCASH_SWAP_URL} color="secondary">
                                        {t('pageHome.features.btnStatistics')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;
