import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Loader = ({ progress, isLoaded }: { progress: number; isLoaded: boolean }) => {
    return (
        <div className={cx('Loader', isLoaded && 'loaded')}>
            <div className={cx('LoaderLeftSide')}>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/USDCASH.svg').default} alt="Token" />
                </span>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/UAHCASH.svg').default} alt="Token" />
                </span>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/LiST.svg').default} alt="Token" />
                </span>
            </div>
            <div className={cx('LoaderRightSide')}>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/MLNK.svg').default} alt="Token" />
                </span>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/LiST.svg').default} alt="Token" />
                </span>
                <span className={cx('LoaderBubble')}>
                    <img src={require('assets/images/tokens/EOS.svg').default} alt="Token" />
                </span>
            </div>
            {!isLoaded && <span className={cx('LoaderProgress')}>{progress.toFixed(2)}%</span>}
        </div>
    );
};

export default Loader;
