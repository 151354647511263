export default {
    header: {
        nav: {
            home: 'EOS wallet',
            home_description: 'Secure and completely\nanonymous EOS wallet',
            paycashswap: 'PayCash Swap',
            paycashswap_description: 'Your best investment assistant'
        },

        btnDownload: 'Download the App'
    },

    footer: {
        appCard: {
            title: 'Secure \nEOS Wallet',
            text: 'EOS wallet with crypto-chat, inheritance,\n token swap and DeFi income functionality'
        },
        tokenCard: {
            title: 'Tokens backed by\n200%',
            btn: 'Learn more'
        },

        nav: {
            policy: 'Privacy policy'
        },
        social: 'Sign up to our social network feeds to follow \nthe latest news and contests',
        contactUs: 'Do get in touch with us if you have any proposals, complaints or simply for any other reason.'
    },

    global: {
        btnDownload: {
            btnAndroid: '<0>Available on</0>Google Play',
            btnIOS: '<0>Available on</0>App Store'
        },

        tokens: {
            USDCASH: 'Dollar',
            RUBCASH: 'Ruble',
            LIRUMOW: 'LiST Russia, Moscow'
        }
    },

    pageHome: {
        intro: {
            title: 'Secure and\u00a0completely\nanonymous <0>EOS\u00a0wallet</0>',
            btnDownload: 'Get the app'
        },

        advantages: {
            title: 'Advantages',
            text: [
                '25 free transactions a day',
                'Token swap',
                'Ability to buy and sell Crypto-cash tokens',
                'Crypto-messenger for secure communication',
                'Income on DeFi projects',
                'Full support of Crypto-cash tokens'
            ]
        },

        transactions: {
            title: 'Transactions.\nFree of charge.\n<0>Every\u00a0day.</0>',
            text: 'To transact on EOS blockchain users have to purchase wallet resources, while we provide our users with 25 daily transactions free of charge',
            btnDownload: 'Get the app',

            tabs: {
                resources: {
                    title: 'Resources',
                    cpu: 'CPU',
                    ram: 'Memory',
                    net: 'Network'
                },
                transactions: {
                    title: 'Free transaction',
                    of: 'from'
                }
            }
        },

        chat: {
            title: 'The best chat\n<0>on\u00a0the blockchain</0>',

            messages: ['Send 12 500 RUBCASH to the wallet 222537.pcash', 'Done'],
            btnDownload: 'Get the app',

            btnReadMore: 'Learn more',

            description:
                'Your messages are completely safe, protected by our end-to-end encryption algorithms and available exclusively on your device. Communicate in a completely anonymous chat. '
        },

        features: {
            exchange: {
                title: 'Token exchange',
                text: 'You can exchange any EOS blockchain tokens in the "SWAP" section of the app.'
            },
            investing: {
                title: 'Income on DeFi projects',
                text: 'Earn income on token exchange commissions by investing in liquidity pools.'
            },
            p2p: {
                title: 'Trade Crypto-cash',
                text: 'Make pay-outs to your card and pay for your purchases using Crypto-cash tokens. You can also trade tokens with other users.'
            },

            orders: 'Orders',
            available: 'Available',
            limit: 'Limit',
            btnSell: 'Sell',
            btnChat: 'Enter chat',

            btnDownload: 'Get the app',
            btnReadMore: 'Learn more',
            btnStatistics: 'View statistics'
        }
    },

    pagePolicy: {
        title: 'Privacy policy'
    }
};
