import React, { Fragment, useEffect } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Header from 'components/Header';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { scrollInto } from 'helpers';
const cx = classNames.bind(styles);

const ID_LIST = {
    intro: 'intro',
    info: 'info',
    data: 'data',
    cookies: 'cookies',
    services: 'services',
    security: 'security',
    otherSites: 'otherSites',
    children: 'children',
    changes: 'changes',
    contactUs: 'contactUs'
};

const PagePolicy = () => {
    const [t] = useTranslation();
    const [activeSection, setActiveSection] = useState(ID_LIST.intro);

    useEffect(() => {
        const intro = document.getElementById(ID_LIST.intro)!;
        const info = document.getElementById(ID_LIST.info)!;
        const data = document.getElementById(ID_LIST.data)!;
        const cookies = document.getElementById(ID_LIST.cookies)!;
        const services = document.getElementById(ID_LIST.services)!;
        const security = document.getElementById(ID_LIST.security)!;
        const otherSites = document.getElementById(ID_LIST.otherSites)!;
        const children = document.getElementById(ID_LIST.children)!;
        const changes = document.getElementById(ID_LIST.changes)!;
        const contactUs = document.getElementById(ID_LIST.contactUs)!;

        const isInView = (el: HTMLElement) => {
            const { top, bottom } = el.getBoundingClientRect();
            return top >= 0 && bottom <= window.innerHeight;
        };

        const onScroll = () => {
            if (isInView(intro)) {
                setActiveSection(ID_LIST.intro);
            } else if (isInView(info)) {
                setActiveSection(ID_LIST.info);
            } else if (isInView(data)) {
                setActiveSection(ID_LIST.data);
            } else if (isInView(cookies)) {
                setActiveSection(ID_LIST.cookies);
            } else if (isInView(services)) {
                setActiveSection(ID_LIST.services);
            } else if (isInView(security)) {
                setActiveSection(ID_LIST.security);
            } else if (isInView(otherSites)) {
                setActiveSection(ID_LIST.otherSites);
            } else if (isInView(children)) {
                setActiveSection(ID_LIST.children);
            } else if (isInView(changes)) {
                setActiveSection(ID_LIST.changes);
            } else if (isInView(contactUs)) {
                setActiveSection(ID_LIST.contactUs);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        const targetLink = document.querySelector<HTMLAnchorElement>(`[data-section-id=${activeSection}]`);

        targetLink?.parentElement?.scrollTo({
            top: 0,
            left: targetLink?.offsetLeft - 16,
            behavior: 'smooth'
        });
    }, [activeSection]);

    const NAV = [
        { id: ID_LIST.intro, value: 'Intro' },
        { id: ID_LIST.info, value: 'Information Collection and Use' },
        { id: ID_LIST.data, value: 'Log Data' },
        { id: ID_LIST.cookies, value: 'Cookies' },
        { id: ID_LIST.services, value: 'Service Providers' },
        { id: ID_LIST.security, value: 'Security' },
        { id: ID_LIST.otherSites, value: 'Links to Other Sites' },
        { id: ID_LIST.children, value: 'Children’s Privacy' },
        { id: ID_LIST.changes, value: 'Changes to This Privacy Policy' },
        { id: ID_LIST.contactUs, value: 'Contact Us' }
    ];

    return (
        <Fragment>
            <Header ready />
            <main className={cx('Component')}>
                <div className={cx('Container')}>
                    <nav className={cx('Nav')}>
                        {NAV.map(({ value, id }) => {
                            return (
                                <a
                                    key={id}
                                    href="/"
                                    data-section-id={id}
                                    onClick={(e) => {
                                        scrollInto(e, id, -100, 'auto');
                                        setTimeout(() => setActiveSection(id), 10);
                                    }}
                                    className={cx(activeSection === id && 'active')}
                                >
                                    {value}
                                </a>
                            );
                        })}
                    </nav>
                    <div className={cx('Content')}>
                        <section id={ID_LIST.intro}>
                            <h1>{t('pagePolicy.title')}</h1>
                            <p>This policy is effective as of 11 Nov. 2020</p>

                            <p>
                                Paycash wallet app was built as a Free app. This SERVICE is provided at no cost and is
                                intended for use as is. x
                            </p>

                            <p>
                                This page is used to inform visitors regarding policies with the collection, use, and
                                disclosure of Personal Information if anyone decided to use our Service.
                            </p>
                            <p>
                                If you choose to use our Service, then you agree to the collection and use of
                                information in relation to this policy. The Personal Information that We collect is used
                                for providing and improving the Service. We will not use or share your information with
                                anyone except as described in this Privacy Policy.
                            </p>
                            <p>
                                The terms used in this Privacy Policy have the same meanings as in our Terms and
                                Conditions, which is accessible at Paycash wallet unless otherwise defined in this
                                Privacy Policy.
                            </p>
                        </section>

                        <section id={ID_LIST.info}>
                            <h2>Information Collection and Use</h2>
                            <p>
                                For a better experience, while using our Service, We may require you to provide us with
                                certain personally identifiable information, including but not limited to NONE. The
                                information that We request will be retained on your device and is not collected by us
                                in any way.
                            </p>
                            <p>
                                The app does use third party services that may collect information used to identify you.
                            </p>
                            <p className={cx('mb-0')}>
                                Link to privacy policy of third party service providers used by the app
                            </p>

                            <div className={cx('LinkGroup')}>
                                <a href="https://policies.google.com/privacy" target="_blank">
                                    Google Play Services
                                </a>
                                <a href="https://firebase.google.com/support/privacy/" target="_blank">
                                    Firebase Crashlytics
                                </a>
                            </div>
                        </section>

                        <section id={ID_LIST.data}>
                            <h2>Log Data</h2>
                            <p>
                                We want to inform you that whenever you use our Service, in a case of an error in the
                                app We collect data and information (through third party products) on your phone called
                                Log Data. This Log Data may include information such as your device Internet Protocol
                                (“IP”) address, device name, operating system version, the configuration of the app when
                                utilizing our Service, the time and date of your use of the Service, and other
                                statistics.
                            </p>
                        </section>

                        <section id={ID_LIST.cookies}>
                            <h2>Cookies</h2>
                            <p>
                                Cookies are files with a small amount of data that are commonly used as anonymous unique
                                identifiers. These are sent to your browser from the websites that you visit and are
                                stored on your device's internal memory.
                            </p>

                            <p>
                                This Service does not use these “cookies” explicitly. However, the app may use third
                                party code and libraries that use “cookies” to collect information and improve their
                                services. You have the option to either accept or refuse these cookies and know when a
                                cookie is being sent to your device. If you choose to refuse our cookies, you may not be
                                able to use some portions of this Service.
                            </p>
                        </section>

                        <section id={ID_LIST.services}>
                            <h2>Service Providers</h2>
                            <p className={cx('mb-0')}>
                                We may employ third-party companies and individuals due to the following reasons:
                            </p>

                            <ul>
                                <li>To facilitate our Service</li>
                                <li>To provide the Service on our behalf</li>
                                <li>To perform Service-related services</li>
                                <li>To assist us in analyzing how our Service is used</li>
                            </ul>

                            <p>
                                We want to inform users of this Service that these third parties have access to your
                                Personal Information. The reason is to perform the tasks assigned to them on our behalf.
                                However, they are obligated not to disclose or use the information for any other
                                purpose.
                            </p>
                        </section>

                        <section id={ID_LIST.security}>
                            <h2>Security</h2>
                            <p>
                                We value your trust in providing us your Personal Information, thus we are striving to
                                use commercially acceptable means of protecting it. But remember that no method of
                                transmission over the internet, or method of electronic storage is 100% secure and
                                reliable, and We cannot guarantee its absolute security.
                            </p>
                        </section>

                        <section id={ID_LIST.otherSites}>
                            <h2>Links to Other Sites</h2>
                            <p>
                                This Service may contain links to other sites. If you click on a third-party link, you
                                will be directed to that site. Note that these external sites are not operated by me.
                                Therefore, We strongly advise you to review the Privacy Policy of these websites. We
                                have no control over and assume no responsibility for the content, privacy policies, or
                                practices of any third-party sites or services.
                            </p>
                        </section>

                        <section id={ID_LIST.children}>
                            <h2>Children’s Privacy</h2>
                            <p>
                                These Services do not address anyone under the age of 13. We do not knowingly collect
                                personally identifiable information from children under 13. In the case We discover that
                                a child under 13 has provided me with personal information, We immediately delete this
                                from our servers. If you are a parent or guardian and you are aware that your child has
                                provided us with personal information, please contact us so that I will be able to do
                                necessary actions.
                            </p>
                        </section>
                        <section id={ID_LIST.changes}>
                            <h2>Changes to This Privacy Policy</h2>
                            <p>
                                We may update our Privacy Policy from time to time. Thus, you are advised to review this
                                page periodically for any changes. We will notify you of any changes by posting the new
                                Privacy Policy on this page.
                            </p>
                            <p>This policy is effective as of 11 Nov. 2020</p>
                        </section>

                        <section id={ID_LIST.contactUs}>
                            <h2>Contact Us</h2>
                            <p>
                                If you have any questions or suggestions about our Privacy Policy, do not hesitate to
                                contact us at email defined on the APP STORE / PLAY MARKET
                            </p>
                        </section>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default PagePolicy;
