import classNames from 'classnames/bind';
import Button from 'components/Button';
import ButtonDownload from 'components/ButtonDownload';
import {
    IconLogoFacebook,
    IconLogoInstagram,
    IconLogoTelegram,
    IconLogoTelegramChat,
    IconLogoTwitter,
    IconLogoYoutube
} from 'components/Icons';
import TabFocusableBtn from 'components/TabFocusableBtn';
import { BREAKPOINT_IPAD, CASHTOKEN_LANDING_URL, ROUTE_MAP, SOCIALS, SUPPORT_EMAIL } from 'constant/indext';
import { calcProgressByScroll, getClientHeight, usePixelRatio } from 'helpers';
import useWidthCondition from 'helpers/useWidthCondition';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

const Footer = () => {
    const [t, { language }] = useTranslation();
    const footerRef = useRef<HTMLElement>(null);
    const isScrollAnimationDisabled = useWidthCondition((w) => w < BREAKPOINT_IPAD);

    const pixelRatio = usePixelRatio();

    useEffect(() => {
        if (isScrollAnimationDisabled) return;
        const onScrollFooterHandler = () => {
            const animateFooterHandler = () => {
                if (!footerRef.current) return;

                const scrollY = window.scrollY;
                const clientHeight = getClientHeight();
                const footerOffsetTop = footerRef.current.offsetTop!;
                const footerHeight = footerRef.current.offsetHeight!;
                const startPoint = footerOffsetTop - clientHeight;

                const progress = calcProgressByScroll(scrollY, startPoint, startPoint + footerHeight);
                footerRef.current.childNodes.forEach((node) => {
                    const element = node as HTMLDivElement;
                    element.style.willChange = progress > 0 ? 'tranform, opacity' : '';
                    element.style.transform = `translateY(-${(50 * (1 - progress)).toFixed(2)}%)`;
                    element.style.opacity = `${progress.toFixed(2)}`;
                });
            };
            window.requestAnimationFrame(animateFooterHandler);
        };

        window.addEventListener('scroll', onScrollFooterHandler);

        const resetAnimation = () => {
            footerRef.current?.childNodes.forEach((node) => {
                const element = node as HTMLDivElement;
                element.style.willChange = '';
                element.style.transform = ``;
                element.style.opacity = '';
            });
        };

        return () => {
            window.removeEventListener('scroll', onScrollFooterHandler);
            resetAnimation();
        };
    }, [isScrollAnimationDisabled]);

    return (
        <footer id="footer" ref={footerRef} className={cx('Component')}>
            <div className={cx('Container')}>
                <div className={cx('CardGroup')}>
                    <div className={cx('Card', 'Card_app')}>
                        <img
                            className={cx('CardImg')}
                            loading="lazy"
                            src={
                                require(`assets/images/footer_card_app${
                                    isScrollAnimationDisabled ? '_phone' : ''
                                }_${language}@${pixelRatio}x.png`).default
                            }
                            alt=""
                        />
                        <h3>{t('footer.appCard.title')}</h3>
                        <p>{t('footer.appCard.text')}</p>
                        <div className={cx('BtnGroup')}>
                            <ButtonDownload color="dark" os="android" />
                            <ButtonDownload color="dark" os="ios" />
                        </div>
                    </div>
                    <div className={cx('Card', 'Card_token')}>
                        <img
                            className={cx('CardImg')}
                            loading="lazy"
                            src={
                                require(`assets/images/footer_card_token${
                                    isScrollAnimationDisabled ? '_phone' : ''
                                }@${pixelRatio}x.png`).default
                            }
                            alt=""
                        />
                        <h3>{t('footer.tokenCard.title')}</h3>
                        <div className={cx('BtnGroup')}>
                            <Button href={CASHTOKEN_LANDING_URL} target="_blank" color="dark" tag="a">
                                {t('footer.tokenCard.btn')}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={cx('Main')}>
                    <div className={cx('Nav')}>
                        <img src={require('assets/images/logo_light.svg').default} alt="PayCash" />
                        <nav>
                            <Button color="link" tag="link" to={ROUTE_MAP.policy}>
                                <>{t('footer.nav.policy')}</>
                            </Button>
                        </nav>
                    </div>

                    <div className={cx('Social')}>
                        <p>{t('footer.social')}</p>
                        <ul>
                            <li>
                                <TabFocusableBtn
                                    className={cx('instagram')}
                                    tag="a"
                                    href={SOCIALS.instagram}
                                    target="_blank"
                                >
                                    <IconLogoInstagram />
                                </TabFocusableBtn>
                            </li>
                            <li>
                                <TabFocusableBtn className={cx('fb')} tag="a" href={SOCIALS.fb} target="_blank">
                                    <IconLogoFacebook />
                                </TabFocusableBtn>
                            </li>
                            <li>
                                <TabFocusableBtn
                                    className={cx('telegram')}
                                    tag="a"
                                    href={SOCIALS.telegramChat}
                                    target="_blank"
                                >
                                    <IconLogoTelegramChat />
                                </TabFocusableBtn>
                            </li>
                            <li>
                                <TabFocusableBtn
                                    className={cx('telegram')}
                                    tag="a"
                                    href={SOCIALS.telegram}
                                    target="_blank"
                                >
                                    <IconLogoTelegram />
                                </TabFocusableBtn>
                            </li>
                            <li>
                                <TabFocusableBtn
                                    className={cx('youtube')}
                                    tag="a"
                                    href={SOCIALS.youtube}
                                    target="_blank"
                                >
                                    <IconLogoYoutube />
                                </TabFocusableBtn>
                            </li>
                            <li>
                                <TabFocusableBtn
                                    className={cx('twitter')}
                                    tag="a"
                                    href={SOCIALS.twitter}
                                    target="_blank"
                                >
                                    <IconLogoTwitter />
                                </TabFocusableBtn>
                            </li>
                        </ul>
                    </div>
                    <div className={cx('Address')}>
                        <address>
                            {'Akadeemia tee 33-319,\nTallinn, 12618, Estonia'}
                            <span>License Nr. FVT000300</span>
                        </address>
                    </div>
                </div>
            </div>
            <div className={cx('SubBlock')}>
                <div className={cx('Container', 'SubBlockContainer')}>
                    <p>
                        {t('footer.contactUs')}

                        <Button color="link" tag="a" href={`mailto:${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}
                        </Button>
                    </p>
                    <p className={cx('Copyright')}>© 2023</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
