import classNames from 'classnames/bind';
import TabFocusableBtn from 'components/TabFocusableBtn';
import { DOWNLOAD_APP } from 'constant/indext';
import { Trans } from 'react-i18next';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

type Props = {
    color: 'light' | 'dark';
    os: 'android' | 'ios';
};

const ButtonDownload = ({ color, os }: Props) => {
    if (os === 'android') {
        return (
            <TabFocusableBtn
                className={cx('Component', color)}
                tag="a"
                rel="noreferrer noopener"
                target="_blank"
                href={DOWNLOAD_APP.android}
            >
                <img src={require(`assets/icons/google-play_${color}.svg`).default} alt="" />
                <span>
                    <Trans i18nKey="global.btnDownload.btnAndroid">
                        <small>.</small>
                    </Trans>
                </span>
            </TabFocusableBtn>
        );
    } else {
        return (
            <TabFocusableBtn
                className={cx('Component', color)}
                tag="a"
                rel="noreferrer noopener"
                target="_blank"
                href={DOWNLOAD_APP.ios}
            >
                <img src={require(`assets/icons/apple_${color}.svg`).default} alt="" />
                <span>
                    <Trans i18nKey="global.btnDownload.btnIOS">
                        <small>.</small>
                    </Trans>
                </span>
            </TabFocusableBtn>
        );
    }
};

export default ButtonDownload;
