import { DOWNLOAD_APP } from 'constant/indext';
import { useMemo } from 'react';

/**
 * Calculate progress of animation with offset
 * @param scrollY - current window.scrollY position
 * @param startPoint - where animation have to be started
 * @param endPoint - where animation have to be ended
 * @returns number in [0,1] range
 */
export const calcProgressByScroll = (scrollY: number, startPoint: number, endPoint: number) => {
    let progress = (scrollY - startPoint) / (endPoint - startPoint);
    if (progress > 1) progress = 1;
    if (progress < 0) progress = 0;

    return progress;
};

export type RGB = number[];
export const constructRgbStyle = (color: RGB) => {
    return color.length ? `rgb(${color.join(', ')})` : '';
};

export const getClientHeight = () => document.body.clientHeight;

export const usePixelRatio = () => useMemo(() => (window.devicePixelRatio > 1 ? 2 : 1), []);

export const scrollInto = (e: React.MouseEvent, id: string, offset = 0, behavior: ScrollBehavior = 'smooth') => {
    e.preventDefault();

    window.scrollTo({
        left: 0,
        top: (document.getElementById(id)?.offsetTop ?? 0) + offset,
        behavior
    });
};
export const scrollIntoFooter = (e: React.MouseEvent) => scrollInto(e, 'footer');

const iOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

const android = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
};

export const isMobilePlatform = () => iOS() || android();

export const getDownloadLink = () => {
    return iOS() ? DOWNLOAD_APP.ios : android() ? DOWNLOAD_APP.android : null;
};

export const getDownloadLinkParams = () => {
    const downloadLink = getDownloadLink();

    return {
        target: downloadLink ? '_blank' : undefined,
        rel: downloadLink ? 'noreferrer noopener' : undefined,
        href: downloadLink ?? '',
        onClick: downloadLink ? undefined : scrollIntoFooter
    };
};

export const addSpacesForThousands = (value?: string | null) => {
    const splitValue = (value ?? '').split('.');
    splitValue[0] = splitValue[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$& ');
    return splitValue.join('.');
};
