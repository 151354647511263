import classNames from 'classnames/bind';
import ButtonDownload from 'components/ButtonDownload';
import React, { Fragment, RefObject, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HomepageTimelines } from '../';
import { getDownloadLinkParams, usePixelRatio } from 'helpers';
import styles from './index.module.scss';
import Loader from './Loader';
import { IconPhone } from 'components/Icons';
const cx = classNames.bind(styles);

const Intro = ({
    introRef,
    loading,
    loadingProgress,
    isScrollAnimationDisabled,
    timelines
}: {
    loading: boolean;
    loadingProgress: number;
    timelines: HomepageTimelines;
    isScrollAnimationDisabled: boolean;
    introRef: RefObject<HTMLDivElement>;
}) => {
    const [t, { language }] = useTranslation();
    const pixelRatio = usePixelRatio();

    useEffect(() => {
        const timeline = timelines.intro;
        if (!timeline || isScrollAnimationDisabled) return;

        const onScrollIntroHandler = () => {
            const animateIntroHandler = () => {
                if (!introRef.current) return;
                if (timeline.progress() > 0) {
                    introRef.current.classList.add(cx('hide'));
                } else {
                    introRef.current.classList.remove(cx('hide'));
                }
            };

            window.requestAnimationFrame(animateIntroHandler);
        };

        const resetStyles = () => {
            introRef.current?.classList.remove(cx('hide'));
        };

        window.addEventListener('scroll', onScrollIntroHandler);
        return () => {
            window.removeEventListener('scroll', onScrollIntroHandler);
            resetStyles();
        };
    }, [timelines, isScrollAnimationDisabled]);
    return (
        <div ref={introRef} className={cx('PreviewScreen')}>
            {!isScrollAnimationDisabled && <Loader progress={loadingProgress} isLoaded={!loading} />}

            {isScrollAnimationDisabled && (
                <img
                    alt=""
                    className={cx('PhoneImg')}
                    src={require(`assets/images/home_intro_phone_${language}@${pixelRatio}x.png`).default}
                />
            )}

            <div className={cx('PreviewScreenContent', !loading && 'show')}>
                <h1>
                    <Trans i18nKey="pageHome.intro.title">
                        <span>.</span>
                    </Trans>
                </h1>
                <div className={cx('PreviewScreenBtnGroup')}>
                    {isScrollAnimationDisabled ? (
                        <a {...getDownloadLinkParams()} className={cx('BtnDownload')}>
                            <span>
                                <IconPhone />
                                {t('pageHome.intro.btnDownload')}
                            </span>
                        </a>
                    ) : (
                        <Fragment>
                            <ButtonDownload color="light" os="android" />
                            <ButtonDownload color="light" os="ios" />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Intro;
