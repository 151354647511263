/**
 * @todo cleanup all file. Improve handling of all animations
 */
import classNames from 'classnames/bind';
import { usePixelRatio } from 'helpers';
import React, { memo, RefObject, useEffect, useRef, useState } from 'react';
import { FINAL_INTRO_FRAME_INDEX, FIRST_FULL_SIZE_FRAME_INDEX, HomepageTimelines, TOTAL_FRAMES } from '.';
import styles from './index.module.scss';
const cx = classNames.bind(styles);

let lastDrawCanvasValues: number[] = [];

type GetImageOffset =
    | ((params: { imgHeight: number; imgWidth: number; canvasHeight: number; canvasWidth: number }) => {
          moveImageX: number;
          moveImageY: number;
      })
    | undefined;
const drawImage = (
    IMAGES: { [key in number]: HTMLImageElement },
    canvas: HTMLCanvasElement | null,
    index: number,
    radius: number,
    getImageOffset?: GetImageOffset
) => {
    const img = IMAGES[index];
    if (!canvas || !img) return;
    const { width: imgWidth, height: imgHeight } = img;
    const { width: canvasWidth, height: canvasHeight } = canvas;

    // We need it to move image on canvas in right moment
    const offset = getImageOffset
        ? getImageOffset({
              imgHeight,
              imgWidth,
              canvasWidth,
              canvasHeight
          })
        : { moveImageX: 0, moveImageY: 0 };

    offset.moveImageX = Math.round(offset.moveImageX);
    offset.moveImageY = Math.round(offset.moveImageY);

    const IMG_X = Math.round(canvasWidth / 2 - imgWidth / 2 - offset.moveImageX);
    const IMG_Y = Math.round(canvasHeight - imgHeight - offset.moveImageY);

    const BACKGROUND_PARAMS = [Math.round(canvasWidth / 2), Math.round(canvasHeight / 2), Math.round(radius)] as const;

    const newCanvasValues = [index, imgHeight, imgWidth, IMG_X, IMG_Y, canvasWidth, canvasHeight, ...BACKGROUND_PARAMS];

    // redraw only if smth changed
    if (newCanvasValues.some((value, i) => lastDrawCanvasValues[i] !== value)) {
        const ctx = canvas.getContext('2d')!;
        lastDrawCanvasValues = newCanvasValues;
        /** clear canvas */
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        /** draw background */
        ctx.beginPath();
        ctx.arc(...BACKGROUND_PARAMS, 0, 2 * Math.PI);
        ctx.fillStyle = '#fff';
        ctx.fill();
        /** draw image */
        ctx.drawImage(IMAGES[index], IMG_X, IMG_Y, imgWidth, imgHeight);
    }
};

const getRadius = (w: number, h: number) => Math.sqrt(w ** 2 + h ** 2) / 2;

const AppCanvas = memo(
    ({
        imagesRef,
        timelines,
        loading
    }: {
        loading: boolean;
        imagesRef: RefObject<{ [key in number]: HTMLImageElement }>;
        timelines: HomepageTimelines;
    }) => {
        const isIntroAnimationCanPlay = useRef(true);
        const pixelRatio = usePixelRatio();
        const isIntroAnimationPlays = useRef(false);
        const phoneRef = useRef<HTMLCanvasElement>(null);
        const [size, setSize] = useState(`${document.body.clientWidth}${document.body.clientHeight}`);

        useEffect(() => {
            const onResize = () => {
                const el = phoneRef.current;
                if (!el) return;
                const { clientWidth, clientHeight } = document.body;
                el.width = clientWidth * pixelRatio;
                el.height = clientHeight * pixelRatio;
                el.style.width = clientWidth + 'px';
                el.style.height = clientHeight + 'px';
                setSize(`${clientWidth}${clientHeight}`);
            };

            onResize();

            window.addEventListener('resize', onResize);

            return () => {
                window.removeEventListener('resize', onResize);
            };
        }, []);

        useEffect(() => {
            if (!timelines.intro || !phoneRef.current || loading) return;

            /** Run intro animation */
            let frame = 1;
            const FRAMES_TO_SHOW = FINAL_INTRO_FRAME_INDEX;
            const INTRO_ANIMATION_DURATION = 800;
            const introAnimation = () => {
                isIntroAnimationPlays.current = true;
                if (phoneRef.current && timelines.intro?.progress() === 0 && frame <= FRAMES_TO_SHOW) {
                    setTimeout(() => {
                        if (!phoneRef.current) return;

                        drawImage(
                            imagesRef.current!,
                            phoneRef.current,
                            frame,
                            getRadius(phoneRef.current.width, phoneRef.current.height)
                        );
                        frame = frame + 1;
                        introAnimation();
                    }, Math.floor(INTRO_ANIMATION_DURATION / FRAMES_TO_SHOW));
                } else {
                    isIntroAnimationPlays.current = false;
                    isIntroAnimationCanPlay.current = false;
                }
            };

            const onScrollAppCanvasHandler = () => {
                const animationAppCanvasHandler = () => {
                    if (!phoneRef.current || !timelines.advantages) return;

                    const initialBgRadius = getRadius(phoneRef.current.width, phoneRef.current.height);
                    const finalBgRadius = 205 * pixelRatio;

                    let FRAME = FINAL_INTRO_FRAME_INDEX;
                    let BG_RADIUS = initialBgRadius;
                    let OFFSET: GetImageOffset = undefined;

                    const rotatingAnimationProgress = timelines.advantages!.get('animatedMobileOffset')!.progress;

                    const movingAnimationProgress = timelines.advantages!.get('hideCircles').progress;
                    if (rotatingAnimationProgress > 0 && rotatingAnimationProgress <= 1) {
                        const startBgRadiusProgress = 0.5;
                        const endBgRadiusProgress = 1;
                        const radiusProgress =
                            (rotatingAnimationProgress - startBgRadiusProgress) /
                            (endBgRadiusProgress - startBgRadiusProgress);
                        FRAME = Math.min(
                            FINAL_INTRO_FRAME_INDEX +
                                Math.floor(rotatingAnimationProgress * (TOTAL_FRAMES - FINAL_INTRO_FRAME_INDEX)),
                            TOTAL_FRAMES
                        );
                        BG_RADIUS = Math.min(
                            finalBgRadius + (initialBgRadius - finalBgRadius) * (1 - radiusProgress),
                            initialBgRadius
                        );

                        OFFSET = ({ canvasHeight, imgHeight }: { canvasHeight: number; imgHeight: number }) => {
                            /** draw phone image with correct position */
                            const progressBreakpoint = FIRST_FULL_SIZE_FRAME_INDEX / TOTAL_FRAMES;
                            const moveImageY =
                                rotatingAnimationProgress >= progressBreakpoint
                                    ? ((canvasHeight - imgHeight) / 2) *
                                      ((rotatingAnimationProgress - progressBreakpoint) / (1 - progressBreakpoint))
                                    : 0;

                            return {
                                moveImageY,
                                moveImageX: 0
                            };
                        };
                    }

                    if (movingAnimationProgress > 0 && movingAnimationProgress <= 1) {
                        FRAME = TOTAL_FRAMES;
                        BG_RADIUS = finalBgRadius * Math.max(1 - movingAnimationProgress * 4, 0);
                        OFFSET = ({ canvasHeight, imgHeight, imgWidth, canvasWidth }) => {
                            return {
                                moveImageY: (canvasHeight - imgHeight) / 2,
                                moveImageX:
                                    (Math.min(
                                        ...[canvasWidth, Math.min(1440, window.innerWidth) - 100 * 2].map(
                                            (i) => i * pixelRatio
                                        )
                                    ) /
                                        2 -
                                        imgWidth / 2 +
                                        // empty borders on image
                                        imgWidth * 0.1) *
                                    movingAnimationProgress
                            };
                        };
                    }

                    if (timelines.transactions!.get('hidingTransactionsInfo').progress) {
                        if (!phoneRef.current.classList.contains(cx('hide')))
                            phoneRef.current.classList.add(cx('hide'));
                    } else {
                        phoneRef.current.classList.remove(cx('hide'));
                    }

                    if (timelines.chat?.progress() === 1) {
                        if (!phoneRef.current.classList.contains(cx('remove')))
                            phoneRef.current.classList.add(cx('remove'));
                    } else {
                        phoneRef.current.classList.remove(cx('remove'));
                    }

                    drawImage(imagesRef.current!, phoneRef.current, FRAME, BG_RADIUS, OFFSET);
                };

                window.requestAnimationFrame(animationAppCanvasHandler);
            };

            /** show component - page is fully loaded at the moment */
            phoneRef.current?.classList.add(cx('show'));

            if (isIntroAnimationCanPlay.current) {
                !isIntroAnimationPlays.current && introAnimation();
            } else {
                isIntroAnimationCanPlay.current = false;
                onScrollAppCanvasHandler();
            }

            window.addEventListener('scroll', onScrollAppCanvasHandler);

            return () => {
                if (phoneRef.current) {
                    const { width: canvasWidth, height: canvasHeight } = phoneRef.current;
                    phoneRef.current.getContext('2d')?.clearRect(0, 0, canvasWidth, canvasHeight);
                }

                lastDrawCanvasValues = [];
                window.removeEventListener('scroll', onScrollAppCanvasHandler);
            };
        }, [size, timelines, loading]);

        return (
            <div className={cx('AppWrapper')}>
                <canvas ref={phoneRef} className={cx('App')} />
            </div>
        );
    }
);

export default AppCanvas;
