export const KEY_DEFAULT_LNG = 'LNG';

export const SUPPORT_EMAIL = 'info@paycash.app';
export const PAYCASH_SWAP_URL = 'https://paycashswap.com';
export const CASHTOKEN_LANDING_URL = 'https://paycashswap.com/about-cash-tokens';

export const ROUTE_MAP = {
    home: '/',
    terms: '/terms',
    policy: '/policy',
    privacyPolicy: '/privacy-for-app',

    faq: '/faq',
    chat: '/chat',
    exchange: '/exchange',
    p2p: '/p2p',
    investing: '/investing'
};

export const DOWNLOAD_APP = {
    ios: 'https://apps.apple.com/app/paycash/id1557721025',
    android: 'https://play.google.com/store/apps/details?id=online.paycash.app'
};

export const SOCIALS = {
    youtube: 'https://www.youtube.com/PayCashLog',
    telegram: 'https://t.me/paycashlog',
    telegramChat: 'https://t.me/paycashchat',
    fb: 'https://www.facebook.com/paycashlog',
    instagram: 'https://www.instagram.com/paycashlog/',
    twitter: 'https://twitter.com/PayCashTweet'
};

/**
 * -----------------------------
 * Screen size breakpoints
 * -----------------------------
 */
export const BREAKPOINT_PHONE = 576;
export const BREAKPOINT_LANDSCAPE = 768;
export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_IPAD = 1280;
export const BREAKPOINT_LAPTOP = 1440;
