import React, { PropsWithChildren } from 'react';
import SlickSlider, { Settings } from 'react-slick';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { forwardRef } from 'react';
const cx = classNames.bind(styles);

const Slider = forwardRef<SlickSlider, PropsWithChildren<Settings>>((settings, ref) => {
    return (
        <SlickSlider
            dots={true}
            arrows={false}
            infinite={true}
            speed={500}
            slidesToShow={1}
            variableWidth={true}
            centerPadding="12px"
            centerMode={true}
            useTransform
            className={cx('Slider')}
            dotsClass={cx('SliderDots')}
            slidesToScroll={1}
            {...settings}
            ref={ref}
        />
    );
});

export default Slider;
